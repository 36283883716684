import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()


import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;

import { Providers, Msal2Provider } from '@microsoft/mgt';
import { TemplateHelper } from '@microsoft/mgt';
TemplateHelper.setBindingSyntax('[[', ']]');

Providers.globalProvider = new Msal2Provider({
  clientId: process.env.VUE_APP_CLIENT_ID,
  authority: `https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}`
});
store.commit('SET_MSAL_PROVIDER', Providers.globalProvider)




createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
