<template>
  <div>
    <template v-if="searchResults == null"/>

    <!-- No Search Results found -->
    <template v-else-if="searchResults.length == 0">
      <v-card>
        <v-list>
          <v-list-item>No results found</v-list-item>
        </v-list>
      </v-card>
    </template>
    <!-- END No Search Results found -->

    <!-- Search Results -->
    <template v-else>
      <v-card>
        <v-list lines="two">
          <v-list-item v-for="i in searchResults" :key="i.document.id">
            <v-list-item-title><a :href=i.document.url target="_blank">{{i.document.pageTitle}}</a></v-list-item-title>
            <v-list-item-subtitle>
              <span class="font-weight-bold">{{formatDate(i.document.upsertDateTime)}}</span> - <span>{{urlDomain(i.document.url)}}</span> 
              &nbsp;<v-icon color="primary" size="x-small" @click="selectDocument(i.document)">mdi-pencil</v-icon>
            </v-list-item-subtitle>
            {{i.document.userContent}}
          </v-list-item>
        </v-list>
      </v-card>
    </template>
    <!-- END Search Results -->

    <!-- Edit Document Dialog -->
    <DialogEditDocument/>
    <!-- END Edit Dialog -->

  </div>
</template>

<script>
/* eslint-disable */
import {mapGetters} from 'vuex'
import store from '@/store'
import DialogEditDocument from '@/components/isAuthenticated/DialogEditDocument.vue'

export default {
  name:'SearchResults' ,
  components:{
    DialogEditDocument
  },
  data(){
    return{
      selectedDocumentId: -1
    }
  },
  computed:{
    ...mapGetters(['searchResults', 'selectedDocument']),
  },
  methods:{
    formatDate(value){
      return value.substring(0,10)
    },
    urlDomain(value){
      return value.replace('http://','').replace('https://','').split(/[/?#]/)[0]
    },
    selectDocument(document){
      store.commit('SET_SELECTED_DOCUMENT', document)
    }
  }
}
</script>