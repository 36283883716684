<template>
  <v-container>
 <!-- Search Form -->
    <v-form>
      <v-container>
        <v-row  justify="space-between">
          <v-text-field label="Search"  variant="underlined" v-model="searchValue" @keydown.esc="resetSearch" @keypress.enter.prevent="routeText" ref="searchTextbox"> 
            <template v-slot:append>
 
              <div class="d-md-none">
                <v-btn variant="text" color="green-darken-3" icon="mdi-magnify" size="small" @click="routeText"></v-btn>
                <v-btn variant="text" color="red" icon="mdi-cancel" size="small"  @click="resetSearch" ></v-btn>     
              </div>
              <div class="d-none d-md-block">
                <v-btn variant="text" color="green-darken-3" @click="routeText">Search</v-btn>
                <v-btn variant="text" color="red"  @click="resetSearch" >Clear</v-btn>     
              </div>
            </template> 
          </v-text-field>
        </v-row>
      </v-container>
    </v-form>
<!-- END Search Form -->

    <SearchResults></SearchResults>

<!-- Snackbars -->
          <v-overlay v-model="isWorking" class="align-center justify-center" contained>
            <v-progress-circular indeterminate color="green" :size="100"></v-progress-circular>
          </v-overlay>
    <!-- <v-snackbar v-model="isWorking">
      Working...
    </v-snackbar> -->
<!-- END Snackbars -->
  </v-container>
</template>

<script>
/* eslint-disable */
import SearchResults from '../isAuthenticated/SearchResults.vue'
import {mapGetters} from 'vuex'
import store from '@/store'


export default {
  name:'SearchQuery',
  components:{ SearchResults, SearchResults },
  data(){
    return{
      searchValue:'',
      snackbar:{
        saved:false,
        error:false,
        deleted:false,
        timeout:3000
      }, 
      timer: null
    }
  },
  mounted(){
    this.timer = setInterval(() => {
      store.dispatch('PING_ASYNC')
    }, 14400000)
  },
  computed:{
    ...mapGetters(['isWorking','msalProvider','apiScopes']),
  },
  methods:{
    resetSearch(){
      this.searchValue = ''
      this.$refs.searchTextbox.focus();
      store.commit('SET_SEARCH_RESULTS', null)
    },
    routeText(){
      if(this.searchValue.toLowerCase().startsWith('http')){
        // this.upsertUrl()
        store.dispatch('UPSERT_URL_ASYNC', this.searchValue)
      }
      else{
        store.dispatch('GET_SEARCH_RESULTS_ASYNC', this.searchValue)
      }
    }
  }
}
</script>