<template>
  <div class="home">
    <Main></Main>
  </div>
</template>

<script>
// @ is an alias to /src

import Main from '@/components/public/Main.vue'
export default {
  name: 'HomeView',
  components: {
    Main    
  }
}
</script>