import { createStore } from 'vuex'
import msal from './modules/msal'
import search from './modules/search'
import axios from "axios"

export default createStore({
  state: {
    isWorking: false
  },
  getters: {
    isWorking: state => {
      return state.isWorking
    }
  },
  mutations: {
    SET_IS_WORKING(state, isWorking){
      state.isWorking = (isWorking === true)
    }
  },
  actions: {
  },
  modules: {
    msal,search
  }
})
