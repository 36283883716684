import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PostLogoutView from '../views/PostLogoutView.vue'
import TermsOfServiceView from '../views/TermsOfServiceView.vue'
import AboutView from '../views/AboutView.vue'

const routes = [
  {path: '/',               name: 'home',           component: HomeView},
  {path: '/logout',         name: 'logout',         component: PostLogoutView},
  {path: '/termsofservice', name: 'termsofservice', component: TermsOfServiceView},
  {path: '/about',          name: 'about',          component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
