<template>
    <v-dialog v-model="showDialogEditDocument">
      <v-card v-if="selectedDocument != null">
        <!-- <v-card-title>
          <span class="text-h5">Edit Search Result</span>
        </v-card-title> -->
        <v-card-text>
          <v-form >
            <v-text-field v-model="selectedDocument.pageTitle" label="Title"></v-text-field>
            <v-text-field v-model="selectedDocument.userContent" label="User Content" multi-Line rows=5></v-text-field>
            <v-switch v-model="selectedDocument.isFlagged" label="Flagged"></v-switch>
            
            <v-text-field v-model="selectedDocument.id" label="id" readonly></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" text @click="cancelDialog">Cancel</v-btn>
          <v-btn color="red-darken-1" text @click="deleteDocument">Delete</v-btn>
          <v-btn color="green-darken-1" text @click="mergeDocument">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import store from '@/store'

export default {
 name:'EditDocument',
 data(){
  return{

  }
 },
 computed:{
    ...mapGetters(['showDialogEditDocument', 'selectedDocument']),
  }, 
  methods:{
    mergeDocument(){
      store.dispatch('MERGE_DOCUMENT_ASYNC')
    },
    deleteDocument(){
      store.dispatch('REMOVE_DOCUMENT_ASYNC')
    },
    cancelDialog(){
      store.commit('SET_SELECTED_DOCUMENT', null)
    }
  }
}
</script>

