/* eslint-disable */
// https://docs.microsoft.com/en-us/graph/toolkit/providers/providers
export default{
  state:{
    msalProvider: null,
    isSignedIn: false,
  },
  getters:{
    msalProvider: state => {
      return state.msalProvider
    },
    isSignedIn: state => {
      return state.isSignedIn
    },
    apiScopes: state => {
      return {scopes: [`api://${process.env.VUE_APP_CLIENT_ID}/user_impersonation`]}
    }
  },
  mutations:{
    SET_MSAL_PROVIDER(state, msalProvider){
      state.msalProvider = msalProvider
    },
    SET_IS_SIGNED_IN(state, isSignedIn){
      state.isSignedIn = isSignedIn
    }
  }
}