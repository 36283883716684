import axios from "axios"

/* eslint-disable */
export default{
  state: {
    searchResults:null,
    selectedDocument:null,
  },
  getters: {
    searchResults: state => {
      return state.searchResults
    },
    selectedDocument: state => {
      return state.selectedDocument
    },
    showDialogEditDocument: state => {
      return state.selectedDocument != null
    }
  },
  mutations:{
    SET_SEARCH_RESULTS(state, searchResults){
      state.searchResults = searchResults
    },
    SET_SELECTED_DOCUMENT(state, selectedDocument){
      state.selectedDocument = selectedDocument == null ? null : {...selectedDocument} 
    },
    REMOVE_DOCUMENT(state){
      let filtered = state.searchResults.filter(item => item.document.id != state.selectedDocument.id)
      state.searchResults = filtered
      state.selectedDocument = null
    },
    MERGE_DOCUMENT(state){
      state.searchResults.forEach(element => {
        if(element.document.id == state.selectedDocument.id)
        {
          element.document = {...state.selectedDocument}
        }
      })
      state.selectedDocument = null
    }
  },
  actions:{
    async GET_SEARCH_RESULTS_ASYNC({dispatch, commit, getters, rootGetters}, searchValue){
      try{
        commit('SET_IS_WORKING', true)
        commit('SET_SEARCH_RESULTS', null)       
        const headers = {'Authorization': `Bearer ${await rootGetters.msalProvider.getAccessToken(rootGetters.apiScopes)}`}
        let route = `Search?q=${searchValue}`
        if(searchValue.toLowerCase().startsWith('!')){
          route = 'Flagged'
        }

        axios.get(route, {headers})
        .then(response => {
          commit('SET_SEARCH_RESULTS', response.data.results)
          // console.log(response.data)
        })
        .catch(error => {
          console.log(error)
          commit('SET_IS_WORKING', false)
        })
        .finally(() => {
          commit('SET_IS_WORKING', false)
        })
      }
      catch(ex){
        console.log('GET_SEARCH_RESULTS_ASYNC error; aborting')
        console.log(ex)
        commit('SET_IS_WORKING', false)
      }
    },
    async UPSERT_URL_ASYNC({dispatch, commit, getters, rootGetters}, searchValue){
      try{
        commit('SET_IS_WORKING', true)
        commit('SET_SEARCH_RESULTS', null)    
        const headers = {'Authorization': `Bearer ${await rootGetters.msalProvider.getAccessToken(rootGetters.apiScopes)}`}
        axios.post(`Upsert`,{'url':searchValue}, {headers})
        .then(response => {
          commit('SET_SEARCH_RESULTS', response.data.results)
        })
        .catch(error => {
          console.log(error)
          commit('SET_IS_WORKING', false)
        })
        .finally(() => {
          commit('SET_IS_WORKING', false)
        })
      }
      catch(ex){
        console.log('UPSERT_URL_ASYNC error; aborting')
        console.log(ex)
        commit('SET_IS_WORKING', false)
      }


    },
    async MERGE_DOCUMENT_ASYNC({dispatch, commit, getters, rootGetters}){
      try{
        commit('SET_IS_WORKING', true)
        const headers = {'Authorization': `Bearer ${await rootGetters.msalProvider.getAccessToken(rootGetters.apiScopes)}`}
        axios.patch(`Merge`, getters.selectedDocument, {headers})
        .then(response => {
          commit('MERGE_DOCUMENT')
        })
        .catch(error => {
          console.log(error)
          commit('SET_IS_WORKING', false)
        })
        .finally(() => {
          commit('SET_IS_WORKING', false)
        })
      }
      catch(ex){
        console.log('MERGE_DOCUMENT_ASYNC error; aborting')
        console.log(ex)
        commit('SET_IS_WORKING', false)
      }
    },
    async REMOVE_DOCUMENT_ASYNC({dispatch, commit, getters, rootGetters}){
      try{
        commit('SET_IS_WORKING', true)
        const headers = {'Authorization': `Bearer ${await rootGetters.msalProvider.getAccessToken(rootGetters.apiScopes)}`}
        axios.delete(`Delete/${getters.selectedDocument.id}`,{headers})
        .then(response => {
          commit('REMOVE_DOCUMENT')
        })
        .catch(error => {
          console.log(error)
          commit('SET_IS_WORKING', false)
        })
        .finally(() => {
          commit('SET_IS_WORKING', false)
        })
      }
      catch(ex){
        console.log('REMOVE_DOCUMENT_ASYNC error; aborting')
        console.log(ex)
        commit('SET_IS_WORKING', false)
      }
      
    },
    async PING_ASYNC({dispatch,commit, getters, rootGetters}){
      const headers = {'Authorization': `Bearer ${await rootGetters.msalProvider.getAccessToken(rootGetters.apiScopes)}`}
      axios.get('Ping',{headers})
    }
  }
}