<template>
  <div>
    <mgt-login ref="mgtLogin"  class="mgt-dark">
      <template data-type="signed-in-button-content">
        Boorch - [[personDetails.displayName]]
      </template>
      <template data-type="signed-out-button-content">
        Boorch - Sign In
      </template>
      <template data-type="flyout-person-details">
        [[personDetails.displayName]]
      </template>

    </mgt-login>    
    <template v-if="isSignedIn">
      <SearchQuery/>
    </template>
  </div>
</template>

<script>
  import store from '@/store'
  import {mapGetters} from 'vuex'
  import SearchQuery from '@/components/isAuthenticated/SearchQuery.vue'

  export default{
    name:'Main',
    components:{
      SearchQuery
    },
    data(){
      return{
        lifeCycle:''
      }
    },
    computed:{
      ...mapGetters(['msalProvider','apiScopes','isSignedIn','isWorking'])
    },
    async mounted(){
    this.isLoading = true
    // console.log(`Main.vue - mounted`)
    this.lifeCycle = `mounted`

    this.$refs.mgtLogin.addEventListener('loginInitiated', () => {
      // console.log(`${Date()} loginInitiated`)
      this.lifeCycle = `loginInitiated`
      this.isLoading = false
    })


    this.$refs.mgtLogin.addEventListener('loginCompleted', () => {
      // console.log(`${Date()} loginCompleted`)
      this.lifeCycle = `loginCompleted`
      store.commit('SET_IS_SIGNED_IN', true)
      this.isLoading = false
      store.dispatch('PING_ASYNC')
    })
  },
  }

</script>